import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'gatsby';
import { SuccessButton } from '@entur/button';
import { ExternalIcon } from '@entur/icons';
import { SubParagraph, LeadParagraph } from '@entur/typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "tavla"
    }}>{`Tavla`}</h1>
    <LeadParagraph mdxType="LeadParagraph">
    With Tavla you can set up your own custom departure boards for all public
    transportation in Norway. The solution is completely free and available to
    everyone.
    </LeadParagraph>
    <Link to="https://tavla.entur.no" mdxType="Link">
    <SuccessButton mdxType="SuccessButton">
        Go to Tavla <ExternalIcon mdxType="ExternalIcon" />
    </SuccessButton>
    </Link>
    <SubParagraph mdxType="SubParagraph">
    For feedback or feature requests please{' '}
    <Link to="https://github.com/entur/tavla" mdxType="Link">visit our Github repository</Link>
    </SubParagraph>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      